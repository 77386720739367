import React from 'react';
import {Modal as CarbonModal} from 'carbon-components-react';
import PropTypes from 'prop-types'

/**
 * Carbon Modal
 */
const Modal = ({setProps, children, is_open, n_primary, n_secondary, ...others}) => {
    const onHide = () => {
      if (setProps) {
        setProps({is_open: false});
      }
    };
  
    return (
      <CarbonModal {...others} open={is_open} onRequestClose={onHide}
        onRequestSubmit={() => setProps({n_primary: n_primary + 1})}
        onSecondarySubmit={() => setProps({n_secondary: n_secondary + 1})}>
        {children}
      </CarbonModal>
    );
  };

Modal.propTypes = {
    /**
     * The id of the button
     */
    id: PropTypes.string.isRequired,
    /**
     * Specify whether the Modal is displaying an alert, error or warning Should go hand in hand with the danger prop.
     */
    alert: PropTypes.bool,
    /**
     * Required props for the accessibility label of the header
     */
    ariaLabel: PropTypes.string,
    /**
     * Specify an label for the close button of the modal; defaults to close
     */
    closeButtonLabel: PropTypes.string,
    /**
     * Specify whether the Modal is for dangerous actions
     */
    danger: PropTypes.bool,
    /**
     * Specify whether the modal contains scrolling content
     */
    hasScrollingContent: PropTypes.bool,
    /**
     * Provide a description for "close" icon that can be read by screen readers
     */
    iconDescription: PropTypes.string,
    /**
     * Specify a label to be read by screen readers on the modal root node
     */
    modalAriaLabel: PropTypes.string,
    /**
     * Specify the content of the modal header title.
     */
    modalHeading: PropTypes.string,
    /**
     * Specify the content of the modal header label.
     */
    modalLabel: PropTypes.string,
    /**
     * Specify whether the Modal is currently open
     */
    is_open: PropTypes.bool,
    /**
     * Specify whether the modal should be button-less
     */
    passiveModal: PropTypes.bool,
    /**
     * Prevent closing on click outside of modal
     */
    preventCloseOnClickOutside: PropTypes.bool,
    /**
     * Specify whether the Button should be disabled, or not
     */
    primaryButtonDisabled: PropTypes.bool,
    /**
     * Specify the text for the primary button
     */
    primaryButtonText: PropTypes.string,
    /**
     * Specify the text for the secondary button
     */
    secondaryButtonText: PropTypes.string,
    /**
     * Specify if Enter key should be used as "submit" action
     */
    shouldSubmitOnEnter: PropTypes.bool,
    /**
     * Specify the size variant.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),

    /**
     * Specify the content of your Button
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    /**
     * Dash function
     */
    setProps: PropTypes.func,
    /**
     * Number of times the primary button has been clicked
     */
    n_primary: PropTypes.number,
    /**
     * Number of times the secondary button has been clicked
     */
    n_secondary: PropTypes.number,

}

Modal.defaultProps = {
    is_open: false,
    n_primary: 0,
    n_secondary: 0
}
export default Modal;
