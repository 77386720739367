import React from 'react'
import './Card.scss'
import PropTypes from 'prop-types'

/**
 * Card to display content
 */
const CardBase = ({children, style, className}) => {
    return (
        <div className={'card ' + className} style={style}>
            {children}
        </div>
    )
};
CardBase.propTypes = {
    /**
     * The inline styles
     */
    style: PropTypes.object,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     The children of the element
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    /**
     * The class of the element
     */
    className: PropTypes.string,
}
CardBase.defaultProps = {
    className: ''
}
export default CardBase;
