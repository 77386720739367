import React from 'react';
import PropTypes from 'prop-types'
import { OrderedList, UnorderedList } from 'carbon-components-react';

/**
 * Carbon List
 */
const List = ({children, isOrdered, setProps, ...others}) => {
    return isOrdered ? <OrderedList {...others}>{children}</OrderedList> : <UnorderedList {...others}>{children}</UnorderedList>

}
List.propTypes = {
    /**
     * The inline styles
     */
    style: PropTypes.object,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     The children of the element
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * The class of the element
     */
    className: PropTypes.string,
    /**
     * Specify whether this list expressive or not
     */
    isExpressive: PropTypes.bool,
    /**
     * Specify whether this list should use native list styles instead of custom counter
     */
    native: PropTypes.bool,
    /**
     * Specify whether this list is nested inside of another nested list
     */
    nested: PropTypes.bool,
    /**
     * Specify whether this list is ordered or not
     */
    isOrdered: PropTypes.bool,
    /**
     * Dash function
     */
    setProps: PropTypes.func,
}
List.defaultProps={
    className: '',
    isOrdered: false
}
export default List;
