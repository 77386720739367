import React from 'react';
import PropTypes from 'prop-types';
import { Slider as CarbonSlider } from 'carbon-components-react'

/**
 * Slider
 */
const Slider = ({
    id, disabled, setProps, ...other
}) => {
    return (
        <CarbonSlider
            id={id}
            disable={disabled.toString()}
            onRelease={({ value }) => setProps({ value })}
            {...other}
        />
    );
}
Slider.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /** Form item name */
    name: PropTypes.string,
    /** The form element type  */
    inputType: PropTypes.string,
    /** The ARIA label for the <input> */
    ariaLabelInput: PropTypes.string,
    /** Label text */
    labelText: PropTypes.string.isRequired,
    /** Disabled */
    disabled: PropTypes.bool,
    /** Light variant */
    light: PropTypes.bool,
    /** Without text input */
    hideTextInput: PropTypes.bool,
    /** The value */
    value: PropTypes.number.isRequired,
    /** The minimum value */
    min: PropTypes.number.isRequired,
    /** The maximum value  */
    max: PropTypes.number.isRequired,
    /** The step  */
    step: PropTypes.number.isRequired,
    /** The step factor for Shift+arrow keys */
    stepMultiplier: PropTypes.number,
    /** Prop passed by Dash */
    setProps: PropTypes.func,

    /**
     * Used to allow user interactions in this component to be persisted when
     * the component - or the page - is refreshed. If `persisted` is truthy and
     * hasn't changed from its previous value, any `persisted_props` that the
     * user has changed while using the app will keep those changes, as long as
     * the new prop value also matches what was given originally.
     * Used in conjunction with `persistence_type` and `persisted_props`.
     */
    persistence: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string, PropTypes.number]
    ),

    /**
     * Properties whose user interactions will persist after refreshing the
     * component or the page.
     */
    persisted_props: PropTypes.arrayOf(
        PropTypes.oneOf(['value'])
    ),

    /**
     * Where persisted user changes will be stored:
     * memory: only kept in memory, reset on page refresh.
     * local: window.localStorage, data is kept after the browser quit.
     * session: window.sessionStorage, data is cleared once the browser quit.
     */
    persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),

}
Slider.defaultProps = {
    disabled: false,
    light: false,
    hideTextInput: false,
    ariaLabelInput: '',
    inputType: 'number',
    stepMultiplier: 5,
    persisted_props: ['value'],
    persistence_type: 'local',
}
export default Slider
