import React, { useRef } from 'react';
import { NumberInput as NumberInputCarbon } from "carbon-components-react";
import PropTypes from 'prop-types';

/**
 * NumberInput component
 */
const NumberInput =
    ({
        setProps,
        ariaLabel,
        ...other
    }) => {
        const input = useRef(null);
        const setValue = () => {
            if (input.current.value) {
                const inputValue = input.current.value
                const value = parseInt(inputValue, 10)
                setProps({ value })
            }
        }
        return <NumberInputCarbon
            ref={input}
            onChange={setValue}
            {...other}
        />
    }
NumberInput.propTypes = {
    /**
     * `true` to allow empty string.
     */
    allowEmpty: PropTypes.bool,

    /**
     * style of the input
     */
    style: PropTypes.object,
    /**
     * Provide a description that would be used to best describe the use case of the NumberInput component
     */
    ariaLabel: PropTypes.string,

    /**
     * Specify an optional className to be applied to the wrapper node
     */
    className: PropTypes.string,

    /**
     * Optional starting value for uncontrolled state
     */
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Specify if the control should be disabled, or not
     */
    disabled: PropTypes.bool,

    /**
     * Provide text that is used alongside the control label for additional help
     */
    helperText: PropTypes.string,

    /**
     * Specify whether you want the underlying label to be visually hidden
     */
    hideLabel: PropTypes.bool,

    /**
     * Provide a description for up/down icons that can be read by screen readers
     */
    iconDescription: PropTypes.string,

    /**
     * Specify a custom `id` for the input
     */
    id: PropTypes.string.isRequired,

    /**
     * Specify if the currently value is invalid.
     */
    invalid: PropTypes.bool,

    /**
     * Message which is displayed if the value is invalid.
     */
    invalidText: PropTypes.string,

    /**
     * Generic `label` that will be used as the textual representation of what
     * this field is for
     */
    label: PropTypes.string,

    /**
     * `true` to use the light version.
     */
    light: PropTypes.bool,

    /**
     * The maximum value.
     */
    max: PropTypes.number,

    /**
     * The minimum value.
     */
    min: PropTypes.number,

    /**
     * Specify if the component should be read-only
     */
    readOnly: PropTypes.bool,

    /**
     * Specify the size of the Number Input. Currently supports either `sm` or `xl` as an option.
     */
    size: PropTypes.oneOf(['sm', 'xl']),

    /**
     * Specify how much the valus should increase/decrease upon clicking on up/down button
     */
    step: PropTypes.number,

    /**
     * Specify the value of the input
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Specify whether the control is currently in warning state
     */
    warn: PropTypes.bool,

    /**
     * Provide the text that is displayed when the control is in warning state
     */
    warnText: PropTypes.string,

    /**
     * Prop passed by dash
     */
    setProps: PropTypes.func,

    /**
     * Used to allow user interactions in this component to be persisted when
     * the component - or the page - is refreshed. If `persisted` is truthy and
     * hasn't changed from its previous value, any `persisted_props` that the
     * user has changed while using the app will keep those changes, as long as
     * the new prop value also matches what was given originally.
     * Used in conjunction with `persistence_type` and `persisted_props`.
     */
    persistence: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string, PropTypes.number]
    ),

    /**
     * Properties whose user interactions will persist after refreshing the
     * component or the page.
     */
    persisted_props: PropTypes.arrayOf(
        PropTypes.oneOf(['value'])
    ),

    /**
     * Where persisted user changes will be stored:
     * memory: only kept in memory, reset on page refresh.
     * local: window.localStorage, data is kept after the browser quit.
     * session: window.sessionStorage, data is cleared once the browser quit.
     */
    persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),

};

NumberInput.defaultProps = {
    disabled: false,
    hideLabel: false,
    iconDescription: 'choose a number',
    step: 1,
    invalid: false,
    invalidText: 'Provide invalidText',
    warn: false,
    warnText: '',
    ariaLabel: 'Numeric input field with increment and decrement buttons',
    helperText: '',
    light: false,
    allowEmpty: false,
    persisted_props: ['value'],
    persistence_type: 'local',
};
export default NumberInput
