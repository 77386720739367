import React, { useRef } from 'react';
import {DatePicker as DatePickerCarbon, DatePickerInput} from "carbon-components-react";
import PropTypes from "prop-types";

/**
 * DatePicker
 */
const DatePicker = ({setProps, persistence, persisted_props, persistence_type, placeholder, labelText, id, value, ...other}) => {
    const input = useRef(null);
    const setValue = () => {
        console.log(input);
        if (input.current.inputField.value) {
            console.log(input.current);
            const value = input.current.inputField.value
            setProps({ value })
        }
    }

    return (
        <DatePickerCarbon id={id} datePickerType='single'
                        //   onChange={({value}) => setProps({value})} 
                        ref={input} onChange={setValue}
                          {...other}
                          >
            <DatePickerInput id={id+"__input"}
                             placeholder={placeholder}
                             labelText={labelText} 
                             value={value} />
        </DatePickerCarbon>
    )
}
DatePicker.propTypes = {
    /**
     * flatpickr prop passthrough. Allows the user to enter a date directly
     * into the input field
     */
    allowInput: PropTypes.bool,
     /**
     * The CSS class names.
     */
    className: PropTypes.string,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string.isRequired,
    /**
     * The date format.
     */
    dateFormat: PropTypes.string,
    /**
     * True to use the light version.
     */
    light: PropTypes.bool,
    /**
     * The language locale used to format the days of the week, months, and numbers.
     * The full list of supported locales can be found here https://github.com/flatpickr/flatpickr/tree/master/src/l10n
     */
    locale: PropTypes.string,
    /**
     * The maximum date that a user can pick to.
     */
    maxDate: PropTypes.string,
    /**
     * The minimum date that a user can start picking from.
     */
    minDate: PropTypes.string,
    /**
     * True to use the short version.
     */
    short: PropTypes.bool,
    /**
     * The value of the date value provided to flatpickr
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

    /** Prop passed by Dash */
    setProps: PropTypes.func,
    /** Placeholder */
    placeholder: PropTypes.string.isRequired,
    /** Label text */
    labelText: PropTypes.string.isRequired,

    /**
     * Used to allow user interactions in this component to be persisted when
     * the component - or the page - is refreshed. If `persisted` is truthy and
     * hasn't changed from its previous value, any `persisted_props` that the
     * user has changed while using the app will keep those changes, as long as
     * the new prop value also matches what was given originally.
     * Used in conjunction with `persistence_type` and `persisted_props`.
     */
     persistence: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string, PropTypes.number]
    ),

    /**
     * Properties whose user interactions will persist after refreshing the
     * component or the page.
     */
    persisted_props: PropTypes.arrayOf(
        PropTypes.oneOf(['value'])
    ),

    /**
     * Where persisted user changes will be stored:
     * memory: only kept in memory, reset on page refresh.
     * local: window.localStorage, data is kept after the browser quit.
     * session: window.sessionStorage, data is cleared once the browser quit.
     */
    persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),
}

DatePicker.defaultProps = {
    persisted_props: ['value'],
    persistence_type: 'local',
}

export default DatePicker;
