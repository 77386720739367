import React from 'react'
import { ClassPrefix as ClassPrefixCarbon } from "carbon-components-react";
import PropTypes from "prop-types";

/**
 * Grid Row
 */
const ClassPrefix = ({children, prefix, id}) => <ClassPrefixCarbon id={id} prefix={prefix}>{children}</ClassPrefixCarbon>
ClassPrefix.propTypes = {
    /**
     * The prefix
     */
    prefix: PropTypes.string,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     The children of the element
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};
ClassPrefix.defaultProps = {
    className: ''
}
export default ClassPrefix;
