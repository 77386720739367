import React from 'react';
import { RadioButton, RadioButtonGroup as CarbonRadioButtonGroup } from "carbon-components-react";
import PropTypes from 'prop-types';

/**
 * Radio Group Component
 */
const RadioButtonGroup = (
    { value, setProps, id, radiosButtons, ...others }) => {
    const buttons = radiosButtons.map(({ label, value, id }) => (
        <RadioButton key={value} labelText={label} id={id} value={value} />
    ));
    return (
        <CarbonRadioButtonGroup id={id} name={id} {...others} valueSelected={value} onChange={(value) => setProps({ value })}>
            {buttons}
        </CarbonRadioButtonGroup>
    );
}

RadioButtonGroup.propTypes = {
    /**
     * Provide a legend to the RadioButtonGroup input that you are
     * exposing to the user
     */
    legendText: PropTypes.node,
    /**
     * The radios buttons inside this group
     */
    radiosButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string, value: PropTypes.string, id: PropTypes.string
        })
    ),
    /**
     * Specify a custom `id` for the radio group
     */
    id: PropTypes.string,
    /**
     * The value selected
     */
    value: PropTypes.string,
    /**
     * Provide where label text should be placed
     */
    labelPosition: PropTypes.oneOf(['left', 'right']),
    /**
     * Provide where radio buttons should be placed
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * Dash function
     */
    setProps: PropTypes.func,
    /**
     * Specify whether the group is disabled
     */
    disabled: PropTypes.bool,
    /**
     * Provide an optional className to be applied to the container node
     */
    className: PropTypes.string,

    /**
     * Used to allow user interactions in this component to be persisted when
     * the component - or the page - is refreshed. If `persisted` is truthy and
     * hasn't changed from its previous value, any `persisted_props` that the
     * user has changed while using the app will keep those changes, as long as
     * the new prop value also matches what was given originally.
     * Used in conjunction with `persistence_type` and `persisted_props`.
     */
    persistence: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string, PropTypes.number]
    ),

    /**
     * Properties whose user interactions will persist after refreshing the
     * component or the page.
     */
    persisted_props: PropTypes.arrayOf(
        PropTypes.oneOf(['value'])
    ),

    /**
     * Where persisted user changes will be stored:
     * memory: only kept in memory, reset on page refresh.
     * local: window.localStorage, data is kept after the browser quit.
     * session: window.sessionStorage, data is cleared once the browser quit.
     */
    persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),

};
RadioButtonGroup.defaultProps = {
    radiosButtons: [],
    persisted_props: ['value'],
    persistence_type: 'local',
};
export default RadioButtonGroup;
