import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as icons from '@carbon/icons-react';
import {
    Header,
    HeaderContainer,
    HeaderMenu,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    HeaderGlobalBar,
    HeaderGlobalAction,
    SideNav,
    SideNavMenuItem,
    SideNavMenu,
    SideNavItems,
    SideNavLink,
    SkipToContent
} from 'carbon-components-react'
import updateLocation from "../internal/updateLocation";
import UIShellContent from "../internal/UIShellContent";

/**
 * UIShell is a default layout with the header and a sidebar
 */
class UIShell extends Component {
    constructor(props) {
        super(props);
        this.state = { windowLocation: window.location.pathname }
    }

    getSideMenuSubItems(parent_index, items) {
        return items.map((item, index) => {
            return (
                <SideNavMenuItem key={parent_index + "-"  + index} href={item.url} onClick={(e) => updateLocation(e, item.url)}
                    isActive={this.state.windowLocation === item.url}>
                    {item.name}
                </SideNavMenuItem>
            )
        });
    }

    getSideMenuItems(items) {
        return items.map((item, index) => {
            let others = {...item};
            delete others.url;
            delete others.items;
            delete others.expanded;
            delete others.name;
            if ("items" in item) {
                return <SideNavMenu defaultExpanded={item.expanded} aria-label={item.name} title={item.name} {...others}>{this.getSideMenuSubItems(index, item.items)}</SideNavMenu>
            }
            return (
                <SideNavLink key={index} href={item.url} onClick={(e) => updateLocation(e, item.url)}
                    isActive={this.state.windowLocation === item.url} {...others}>{item.name}</SideNavLink>
            )
        });
    }
    getHeaderMenuItems(parent_index, items) {
        return items.map((item, index) => {
            if ("items" in item) {
                return <HeaderMenu aria-label={item.name} menuLinkName={item.name}>{this.getHeaderMenuItems(index, item.items)}</HeaderMenu>
            }
            return (
                <HeaderMenuItem key={parent_index + "-"  + index} href={item.url} onClick={(e) => updateLocation(e, item.url)}
                    isCurrentPage={this.state.windowLocation === item.url}>{item.name}</HeaderMenuItem>
            )
        });
    }
    getHeaderGlobalItems(items) {
        return items.map((item, index) => {
            var CurrentIcon = icons[item.icon];
            return (
                <HeaderGlobalAction key={index} aria-label={item.name} onClick={(e) => updateLocation(e, item.url)}
                    isCurrentPage={this.state.windowLocation === item.url}>
                    <CurrentIcon />
                </HeaderGlobalAction>
            )
        });
    }

    componentDidMount() {
        window.addEventListener(
            '_dashprivate_pushstate',
            () => this.setState({ windowLocation: window.location.pathname }))
    }

    render() {
        const { sidebarItems, headerItems, headerGlobalItems, children, name } = this.props;
        return (
            <HeaderContainer render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                <Header aria-label={"Milliman " + name}>
                    <SkipToContent />
                    {sidebarItems.length > 0 &&
                        <HeaderMenuButton
                            aria-label="Open menu"
                            onClick={onClickSideNavExpand}
                            isActive={isSideNavExpanded}
                        />
                    }
                    <HeaderName href={'/'} prefix="Milliman">
                        {name}
                    </HeaderName>
                    {sidebarItems.length > 0 &&
                        <SideNav
                            aria-label="Side navigation"
                            expanded={isSideNavExpanded}>
                            <SideNavItems>
                                {this.getSideMenuItems(sidebarItems)}
                                {children}
                            </SideNavItems>
                        </SideNav>
                    }
                    {headerItems.length > 0 &&
                        <HeaderNavigation aria-label={name}>
                            {this.getHeaderMenuItems(0, headerItems)}
                        </HeaderNavigation>}
                    {headerGlobalItems.length > 0 &&
                        <HeaderGlobalBar aria-label={name}>
                            {this.getHeaderGlobalItems(headerGlobalItems)}
                        </HeaderGlobalBar>}
                </Header>
            )}>
            </HeaderContainer>
        );
    }
}

UIShell.propTypes = {
    /** Element id */
    id: PropTypes.string,
    /** Platform Name */
    name: PropTypes.string.isRequired,
    /** Items of the sidebar*/
    sidebarItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            expanded: PropTypes.bool
        })
    ),
    /**
     * Items of the header
     */
    headerItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    /**
     * Items of the header global (icons on the right)
     */
     headerGlobalItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    /**
     * Provide the content for the sidenav
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    /** Prop passed by Dash */
    setProps: PropTypes.func,
    /**
     * Object that holds the loading state object coming from dash-renderer
     */
    loading_state: PropTypes.shape({
        /**
         * Determines if the component is loading or not
         */
        is_loading: PropTypes.bool,
        /**
         * Holds which property is loading
         */
        prop_name: PropTypes.string,
        /**
         * Holds the name of the component that is loading
         */
        component_name: PropTypes.string,
    }),
}

UIShell.defaultProps = {
    sidebarItems: [],
    headerItems: [],
    headerGlobalItems: []
}

export default UIShell;
