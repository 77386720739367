import React from 'react';
import PropTypes from 'prop-types'
import { ToastNotification, InlineNotification } from 'carbon-components-react';

/**
 * Carbon List
 */
const Notification = ({children, type, setProps, ...others}) => {
    return type === "toast" ? <ToastNotification {...others}>{children}</ToastNotification> : <InlineNotification {...others}>{children}</InlineNotification>

}

// actions?: React.ReactNode | undefined;

Notification.propTypes = {
    /**
     * The inline styles
     */
    style: PropTypes.object,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     * The type (toast or inline).
     */
    type: PropTypes.oneOf(["toast", "inline"]),
    /**
     The children of the element
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * The class of the element
     */
    className: PropTypes.string,
    /**
     * Hide the close button
     */
     hideCloseButton: PropTypes.bool,
    /**
     * Description of the close icon
     */
     iconDescription: PropTypes.string,
    /**
     * The kind of notification
     */
     kind: PropTypes.oneOf(["error", "info", "info-square", "success", "warning", "warning-alt"]),
    /**
     * Display in low contrast?
     */
     lowContrast: PropTypes.bool,
    /**
     * Descriptoin of the status icon.
     */
     statusIconDescription: PropTypes.string,
    /**
     * The subtitle
     */
     subtitle: PropTypes.string,
    /**
     * The main message
     */
    title: PropTypes.string.isRequired,
    /**
     * Dash function
     */
    setProps: PropTypes.func,
}
Notification.defaultProps={
    type: 'toast',
}
export default Notification;
