import React from 'react';
import PropTypes from 'prop-types'
import { ListItem as CarbonListItem } from 'carbon-components-react';

/**
 * Carbon ListItem
 */
const ListItem = ({children, setProps, ...others}) => <CarbonListItem {...others}>{children}</CarbonListItem>;

ListItem.propTypes = {
    /**
     * The inline styles
     */
    style: PropTypes.object,
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,
    /**
     The children of the element
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * The class of the element
     */
    className: PropTypes.string,
    /**
     * Dash function
     */
     setProps: PropTypes.func,
}
ListItem.defaultProps={
    className: '',
}
export default ListItem;
