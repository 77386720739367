import React from 'react';
import {ButtonSet as CarbonButtonSet} from 'carbon-components-react';
import PropTypes from 'prop-types'

/**
 * Carbon ButtonSet
 */
const ButtonSet = ({setProps, children, ...others}) => {
    return <CarbonButtonSet {...others}>
        {children}
    </CarbonButtonSet>
}

ButtonSet.propTypes = {
    /**
     * Specify the content of your Button set
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    /**
     * jsx Style
     */
    style: PropTypes.object,
    /**
     * Specify an optional className to be added to your Button
     */
    className: PropTypes.string,
    /**
     * Specify whether the Button set should be stacked, or not
     */
    stacked: PropTypes.bool,
    /**
     * Dash function
     */
    setProps: PropTypes.func
}

ButtonSet.defaultProps = {
}
export default ButtonSet;
