import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import parse from 'html-react-parser';

const Checkbox = props => {
  const {
    value,
    disabled,
    className,
    style,
    id,
    inputClassName,
    inputStyle,
    label,
    label_id,
    labelStyle,
    labelClassName,
    name,
    setProps
  } = props;

  return (
    <div
      className={classNames('bx--form-item', 'bx--checkbox-wrapper', className)}
      style={style}
    >
      <input
        id={id}
        name={name}
        checked={value}
        className={classNames(
          'bx--checkbox',
          inputClassName
        )}
        disabled={disabled}
        style={inputStyle}
        type="checkbox"
        onChange={() => {
          if (!disabled) {
            if (setProps) {
              setProps({value: !value});
            }
          }
        }}
      />
      <label
        id={label_id}
        style={labelStyle}
        className={classNames(
          labelClassName,
          'bx--checkbox-label'
        )}
        htmlFor={id}
      >
        {parse(label)}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * The ID of this component, used to identify dash components in callbacks.
   * The ID needs to be unique across all of the components in an app.
   */
  id: PropTypes.string,

  /**
   * Container class
   */
  className: PropTypes.string,

  /**
   * Container style
   */
  style: PropTypes.object,

  /**
   * Input inline style
   */
  inputStyle: PropTypes.object,
  /**
   * Input class
   */
  inputClassName: PropTypes.string,

  /**
   * Label
   */
  label: PropTypes.string,

  /**
   * Label ID
   */
  label_id: PropTypes.string,

  /**
   * Label style
   */
  labelStyle: PropTypes.object,

  /**
   * Label class.
   */
  labelClassName: PropTypes.string,

  /**
   * The name of the control, which is submitted with the form data.
   */
  name: PropTypes.string,

  /**
   * Disable the Checkbox.
   **/
  disabled: PropTypes.bool,

  /**
   * The value of the input.
   **/
  value: PropTypes.bool,

  /**
   * Used to allow user interactions in this component to be persisted when
   * the component - or the page - is refreshed. If `persisted` is truthy and
   * hasn't changed from its previous value, a `value` that the user has
   * changed while using the app will keep that change, as long as
   * the new `value` also matches what was given originally.
   * Used in conjunction with `persistence_type`.
   */
  persistence: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),

  /**
   * Properties whose user interactions will persist after refreshing the
   * component or the page. Since only `value` is allowed this prop can
   * normally be ignored.
   */
  persisted_props: PropTypes.arrayOf(PropTypes.oneOf(['value'])),

  /**
   * Where persisted user changes will be stored:
   * memory: only kept in memory, reset on page refresh.
   * local: window.localStorage, data is kept after the browser quit.
   * session: window.sessionStorage, data is cleared once the browser quit.
   */
  persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),

  /**
   * Dash-assigned callback that gets fired when the value changes.
   */
  setProps: PropTypes.func
};

Checkbox.defaultProps = {
  style: {},
  className: '',
  inputStyle: {},
  inputClassName: '',
  labelStyle: {},
  labelClassName: '',
  persisted_props: ['value'],
  persistence_type: 'local',
  value: false,
  disabled: false
};

export default Checkbox;


// /**
//  * Checkbox Input
//  */
// const Checkbox = ({ id, label, value, setProps, persistence, persisted_props, persistence_type, ...other }) => {
//     return <CarbonCheckbox
//         id={id} labelText={label} checked={value}
//         onChange={() => {
//             if (setProps) {
//                 setProps({ value: !value });
//             }
//         }} {...other}
//     />
// }

// Checkbox.propTypes = {
//     /**
//      * Provide an `id` to uniquely identify the Checkbox input
//      */
//     id: PropTypes.string.isRequired,
//     /**
//      * Provide a label to provide a description of the Checkbox input that you are
//      * exposing to the user
//      */
//     label: PropTypes.node.isRequired,
//     /**
//      * Specify whether the label should be hidden, or not
//      */
//     hideLabel: PropTypes.bool,
//     /**
//      * Specify whether the Checkbox should be disabled
//      */
//     disabled: PropTypes.bool,
//     /**
//      * Specify whether the underlying input is checked
//      */
//     value: PropTypes.bool,
//     /**
//      * Specify a title for the <label> node for the Checkbox
//      */
//     title: PropTypes.string,
//     /**
//      * Dash function
//      */
//     setProps: PropTypes.func,
//     /**
//      * The inline styles
//      */
//     style: PropTypes.object,
//     /**
//      * Specify an optional className to be applied to the <label> node
//      */
//     className: PropTypes.string,

//     /**
//      * Used to allow user interactions in this component to be persisted when
//      * the component - or the page - is refreshed. If `persisted` is truthy and
//      * hasn't changed from its previous value, any `persisted_props` that the
//      * user has changed while using the app will keep those changes, as long as
//      * the new prop value also matches what was given originally.
//      * Used in conjunction with `persistence_type` and `persisted_props`.
//      */
//     persistence: PropTypes.oneOfType(
//         [PropTypes.bool, PropTypes.string, PropTypes.number]
//     ),

//     /**
//      * Properties whose user interactions will persist after refreshing the
//      * component or the page.
//      */
//     persisted_props: PropTypes.arrayOf(
//         PropTypes.oneOf(['value'])
//     ),

//     /**
//      * Where persisted user changes will be stored:
//      * memory: only kept in memory, reset on page refresh.
//      * local: window.localStorage, data is kept after the browser quit.
//      * session: window.sessionStorage, data is cleared once the browser quit.
//      */
//     persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),
// }

// Checkbox.defautProps = {
//     persisted_props: ['value'],
//     persistence_type: 'local',
//     value: false,
// }
// export default Checkbox
