import React from 'react'
import { Dropdown as CarbonDropdown } from "carbon-components-react";
import PropTypes from 'prop-types'

/**
 * Dropdown component
 */
const Dropdown = ({ setProps, value, options, persistence, persisted_props, persistence_type, ...others }) => {
    const selectedItems = options.find(item => 'value' in item ? value === item.value : value === item);
    return <CarbonDropdown
        onChange={({ selectedItem }) => {
            const value = 'value' in selectedItem ? selectedItem.value : selectedItem;
            setProps({ value })
        }}
        selectedItem={selectedItems}
        itemToString={(item) => item.label ? item.label : item}
        items={options}
        {...others}
    />
}
Dropdown.propTypes = {
    /**
     * Disable the control
     */
    disabled: PropTypes.bool,

    /**
     * List of items
     */
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
    ])).isRequired,

    /**
     * Specify a custom `id`
     */
    id: PropTypes.string.isRequired,

    /**
     * Specify which type of dropdown you need (e.g. "inline")
     */
    type: PropTypes.string,

    /**
     * Specify whether the label should be hidden
     */
    hideLabel: PropTypes.bool,

    /**
     * Specify if the currently selected value is invalid.
     */
    invalid: PropTypes.bool,

    /**
     * Message which is displayed if the value is invalid.
     */
    invalidText: PropTypes.string,


    /**
     * Generic `label` that will be used as the textual representation of what
     * this field is for
     */
    label: PropTypes.node.isRequired,

    /**
     * Callback function for translating ListBoxMenuIcon SVG title
     */
    translateWithId: PropTypes.func,

    /**
     * 'aria-label' of the ListBox component.
     */
    ariaLabel: PropTypes.string,

    /**
     * In the case you want to control the dropdown selection entirely.
     */
    value: PropTypes.any,

    /**
     * `true` to use the light version.
     */
    light: PropTypes.bool,

    /**
     * Provide the title text that will be read by a screen reader when
     * visiting this control
     */
    titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Provide helper text that is used alongside the control label for
     * additional help
     */
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Function passed by Dash
     */
    setProps: PropTypes.func,

    /**
     * Style of the component
     */
    style: PropTypes.object,

    /**
     * Used to allow user interactions in this component to be persisted when
     * the component - or the page - is refreshed. If `persisted` is truthy and
     * hasn't changed from its previous value, any `persisted_props` that the
     * user has changed while using the app will keep those changes, as long as
     * the new prop value also matches what was given originally.
     * Used in conjunction with `persistence_type` and `persisted_props`.
     */
    persistence: PropTypes.oneOfType(
        [PropTypes.bool, PropTypes.string, PropTypes.number]
    ),

    /**
     * Properties whose user interactions will persist after refreshing the
     * component or the page.
     */
    persisted_props: PropTypes.arrayOf(
        PropTypes.oneOf(['value'])
    ),

    /**
     * Where persisted user changes will be stored:
     * memory: only kept in memory, reset on page refresh.
     * local: window.localStorage, data is kept after the browser quit.
     * session: window.sessionStorage, data is cleared once the browser quit.
     */
    persistence_type: PropTypes.oneOf(['local', 'session', 'memory']),

};
Dropdown.defaultProps = {
    options: [],
    persisted_props: ['value'],
    persistence_type: 'local',
}
export default Dropdown;
